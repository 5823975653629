import React from 'react';
import { connect } from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import { retrieve, reset } from '../../../actions/specialist/show';
import {RootState} from "../../../store/reducers";
import {authTokenSelector, CustomCard} from "meditrip-common-web";
import {ICertificate, ILanguage, IMembershipAndAwards} from '../../../store/reducers/specialistSlice';
import {
    retrievedSpecialistErrorSelector,
    retrievedSpecialistSelector,
    retrievedSpecialistLoadingSelector,
    retrievedSpecialistEventSourceSelector,
} from "../../../store/selectors/specialistSelectors";

interface IConnectedClinicSpecialistViewProps {
    readonly retrieved?: any;
    readonly loading: boolean;
    readonly error?: string;
    readonly eventSource?: EventSource;
    readonly deleteError?: string;
    readonly deleteLoading: boolean;
    readonly authToken: string;
    readonly retrieve: typeof retrieve;
    // readonly reset: typeof reset;
    readonly reset: any;
}

interface IExternalClinicSpecialistViewProps {}

interface IClinicSpecialistViewProps extends
    IConnectedClinicSpecialistViewProps,
    IExternalClinicSpecialistViewProps,
    RouteComponentProps {}

// toDo PPP this component is duplicate of clinic-web/src/components/Specialists/SpecialistView/index.tsx - unify
class ClinicSpecialistView extends React.Component<IClinicSpecialistViewProps> {

    componentDidMount() {
        const slug = this.props.match.params;
        this.props.retrieve(`doctor/${(slug as any).id}`, this.props.authToken); // toDo change backend for 'specialist'
    }

    componentWillUnmount() {
        if (this.props.eventSource) {
            this.props.reset(this.props.eventSource);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">Specialist</div>
                            <div className="action-container">
                                {/*<button className="btn btn-small btn-theme"*/}
                                {/*        type="button"*/}
                                {/*        onClick={() => this.toggleModal()}>+ Add Specialist</button>*/}
                                <div onClick={() => this.props.history.goBack()} className="btn btn-no-outline">
                                    <span className="feather icon-chevron-left"/>
                                    Back
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>

                                { this.renderSpecialistData() }

                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderSpecialistData() {
        if(this.props.loading) {
            return null;
        }

        const item = this.props.retrieved;

        if (!item || !item.account) {
            return <span>Invalid data</span>
        }

        return (
            <table className="data-table">
                <thead>
                <tr>
                    <th className="w-25"></th>
                    <th>
                        {item.account.firstName} {item.account.lastName}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Medical degree</td>
                    <td>{item.medicalDegrees}</td>
                </tr>
                <tr>
                    <td>Academic degree</td>
                    <td>{item.academicDegree}</td>
                </tr>
                <tr>
                    <td>Phone</td>
                    <td>{item.account.phone}</td>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>{item['description']}</td>
                </tr>
                <tr>
                    <td>Education</td>
                    <td>{item['education']}</td>
                </tr>
                <tr>
                    <td>Years of Experience</td>
                    <td>{item['yearsExperience']}</td>
                </tr>
                <tr>
                    <td>Languages</td>
                    <td className="d-flex flex-column">
                        {item['languages'].map((language: ILanguage) => {
                            return <span key={language.title}>{language.title}</span>
                        })}
                    </td>
                </tr>
                <tr>
                    <td>Certificates</td>
                    <td className="d-flex flex-column">
                        {item['certificates'].map((certificate: ICertificate) => {
                            return <span key={certificate.title}>{certificate.title}</span>
                        })}
                    </td>
                </tr>
                <tr>
                    <td>Membership and Awards</td>
                    <td className="d-flex flex-column">
                        {item['membershipAndAwards'].map((item: IMembershipAndAwards) => {
                            return <span key={item.title}>{item.title}</span>
                        })}
                    </td>
                </tr>
                <tr>
                    <td>Treatments</td>
                    <td className="d-flex flex-column">
                        {item['treatmentTypes'].map((treatment: any) => {
                            return <span key={treatment.id}>{treatment.name}</span>
                        })}
                    </td>
                </tr>
                <tr>
                    <td>Specializations</td>
                    <td className="d-flex flex-column">
                        {item['specializations'].map((specialization: any) => {
                            return <span key={specialization.id}>{specialization.name}</span>
                        })}</td>
                </tr>
                </tbody>
            </table>
        );
    }
}

export default connect(
    (state: RootState) => ({
        retrieved: retrievedSpecialistSelector(state),
        error: retrievedSpecialistErrorSelector(state),
        loading: retrievedSpecialistLoadingSelector(state),
        eventSource: retrievedSpecialistEventSourceSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        retrieve,
        reset,
    }
)(ClinicSpecialistView);
