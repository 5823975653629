import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";

export function addTreatmentTypeAPI(
  accessToken: string,
  treatmentCategory: string,
  name: string,
  specialists: string[] = []
): Observable<any> {
  return treatmentTypeAPI.post(
    `treatment-type`,
    {
      treatmentCategory: treatmentCategory,
      name: name,
      doctors: specialists,
    },
    new RestQueryParams(),
    {
      Authorization: `Bearer ${accessToken}`
    }
  );
}
