import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../../store/reducers";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
    ValidationRules
} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {addSubscriptionDefinitionAPI, IAddSubscriptionPayload} from "../../../../../api/addSubscriptionDefinition";
import {addSubscriptionFormConfig} from "../../../../SubscriptionPlans/AddSubscriptionPlan/formConfig";
import {proposeSubscriptionPlanAPI} from "../../../../../api/proposeSubscriptionPlan";
import {archiveProposedPlanAPI} from "../../../../../api/archiveProposedPlan";


interface IConnectedProposePlanModalProps {
    readonly authToken: string;
}

interface IProposePlanModalProps extends IConnectedProposePlanModalProps,
    RouteComponentProps,
    WithTranslation {
    addSubscriptionModalShown: boolean;
    closeSubscriptionModal: any;
    toggleSubscriptionModal: any;
    clinicId: string;
    proposedPlans: {[key: string]: any}[];
}

interface IProposePlanModalState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    value: any;
}

class ProposePlanModal extends React.Component<IProposePlanModalProps, IProposePlanModalState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IProposePlanModalProps) {
        super(props);

        this.state = {
            formConfig: addSubscriptionFormConfig,
            isFormValid: true,
            isLoading: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        this.updateButtonText('subscriptionPlans.proposePlan');
    }

    componentDidUpdate(
        prevProps: Readonly<IProposePlanModalProps>,
        prevState: Readonly<IProposePlanModalState>,
        snapshot?: any
    ): void {

    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.addSubscriptionModalShown} closeModal={this.props.closeSubscriptionModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                    <Translation text={'subscriptionPlans.proposePlan'}/>


                                <button className="btn-modal-close" onClick={() => {
                                    this.setState({value: null});
                                    this.props.toggleSubscriptionModal();
                                }}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body aftercare-event-modal">
                                <Form config={this.state.formConfig}
                                      onValueStateChange={this.onValueStateChange}
                                    // onValidationStateChange={this.onValidationStateChange}
                                      value={this.state.value}
                                      controlName={'addSubscriptionPlanForm'}
                                      submitForm={this.addSubscription}/>

                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        Object.keys(value).map((key: string) => {
            if (key === 'onlineConsultation' &&
                value[key] !== null &&
                value[key] !== this.state.value?.onlineConsultation) {
                this.updateOnlineConsultationControls(value[key])
            }

            if (key === 'clinicWidget' && value[key] !== null) {
                this.updateWidgetAmountControl(value[key]);
            }
        });

        this.setState({value: value});
    };

    private addSubscription = () => {
        this.setState({isLoading: true});
        const value = this.state.value,
            payload: IAddSubscriptionPayload = {
                name: value.planName,
                description: '',
                price: {
                    amount: (Number(value.planPrice) * 100).toString(),
                    currency: {
                        code: 'EUR'
                    }
                },
                duration: 30,
                subscriptionRestrictions: {
                    consultationTimeLimit: value.telemedicineHours ? Number(value.telemedicineHours) * 60 : 0,
                    clinicCalendarWidgetAvailable: value.clinicWidget,
                    appCalendarWidgetAvailable: value.meditripWidget,
                    treatmentPlanEnabled: true,
                    calendarCountLimit: value.widgetAmount ? Number(value.widgetAmount) : 0
                }
            };

        if (this.props.proposedPlans && this.props.proposedPlans.length) {
            this.subscriptions.push(this.handleArchiveSubscriptionAPI(this.props.proposedPlans[0].id, payload).subscribe());
        }
        this.subscriptions.push(this.handleAddSubscriptionAPI(payload).subscribe());
    };

    private handleAddSubscriptionAPI(payload: IAddSubscriptionPayload) {
        this.setState({isLoading: true});

        return addSubscriptionDefinitionAPI(
            this.props.authToken,
            payload,
            false,
            null,
            true
        ).pipe(
            map((resp: any) => {
                proposeSubscriptionPlanAPI(this.props.authToken, resp.id, this.props.clinicId).pipe(
                    tap(() => {
                        this.setState({isLoading: false, value: null});
                        this.props.toggleSubscriptionModal();
                        this.alertManager.addAlert("Subscription plan was proposed to clinic.");
                    }),
                    catchError((error: any) => {
                        return of(this.alertManager.handleApiError(error));
                    })
                ).subscribe();
            }),
            catchError((error: any) => {
                return of(this.alertManager.handleApiError(error));
            })
        )
    }

    private handleArchiveSubscriptionAPI(id: string, payload: IAddSubscriptionPayload) {
        this.setState({isLoading: true});

        return archiveProposedPlanAPI(this.props.authToken, id).pipe(
            map(() => {
                this.handleAddSubscriptionAPI(payload);
            }),
            catchError((error: any) => {
                return of(this.alertManager.handleApiError(error));
            })
        )
    }

    private updateButtonText = (btnText: string) => {
        const updatedFormConfig = Object.assign({}, addSubscriptionFormConfig);
        updatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'submitButton') {
                        control.controls[key].btnText = btnText;
                    }
                });
            }

            return control;
        });

        return this.setState({formConfig: updatedFormConfig});
    };

    private updateOnlineConsultationControls(value: string): void {
        const cloneFormConfig = Object.assign({}, this.state.formConfig);
        cloneFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (value) {
                        let requiredField = [
                            { name: ValidationRules.IS_REQUIRED },
                        ];
                        if (key === 'telemedicineHours') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'clinicWidget') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'meditripWidget') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                    } else {
                        if (key === 'telemedicineHours') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'clinicWidget') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'meditripWidget') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                    }
                });
            }

            return control;
        });

        this.setState({
            formConfig: cloneFormConfig,
        });
    }

    private updateWidgetAmountControl(value: string): void {
        const cloneFormConfig = Object.assign({}, this.state.formConfig);
        cloneFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (value) {
                        let requiredField = [
                            { name: ValidationRules.IS_REQUIRED },
                        ];
                        if (key === 'widgetAmount') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                    } else {
                        if (key === 'widgetAmount') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                    }
                });
            }

            return control;
        });

        this.setState({
            formConfig: cloneFormConfig,
        });
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(withRouter(ProposePlanModal)));
