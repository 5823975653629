import {Observable} from "rxjs";
import {RestQueryParams} from "meditrip-common-web";
import {clinicAPI} from "./provider/clinicAPI";
import {IRecommendationDefinitionPayload} from "./createRecomendationDefinitionAPI";

export function updateRecommendationDefinitionAPI(
    authToken: string | undefined,
    recommendationId: string,
    recommendationDetails: IRecommendationDefinitionPayload
): Observable<any> {
    const payload: IRecommendationDefinitionPayload = {
        type: recommendationDetails.type,
        name: recommendationDetails.name,
        description: recommendationDetails.description,
        startNotificationPropagation: recommendationDetails.startNotificationPropagation,
        cyclic: recommendationDetails.cyclic,
        cycleInterval: recommendationDetails.cycleInterval,
        treatmentPointEndsAtInterval: recommendationDetails.treatmentPointEndsAtInterval,
        defaultDefinition: recommendationDetails.defaultDefinition,
        notificationType: recommendationDetails.notificationType
    };

    return clinicAPI.put(
        `treatment_point_definitions/${recommendationId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
