import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'meditrip-common-web';

export const addTreatmentCategoryFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Treatment Category Name',
                    label: 'Treatment Category Name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            }
        }, {
            controlType: 'group',
            key: 'treatment_category_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'treatmentCategories.addModal.addBtn',
                },
            },
        },
    ]
};
