import {FormControlType, IFormConfig, InputType} from 'meditrip-common-web';

export const clinicDetailsFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'email',
            controlType: 'group',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'clinicView.form.placeholders.login',
                    label: 'clinicView.form.labels.login',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12',
                    disabled: true
                }
            },
        }
    ]
};
