import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import { catchError, switchMap, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { PayloadAction } from "redux-starter-kit";
import {
    addAlert,
    authTokenSelector,
    getClinicProfile,
    getClinicProfileAPI,
    IGetClinicProfile,
    setClinicProfileStateLoading,
    setClinicProfileState,
    updateClinicProfile,
    IUpdateClinicProfile,
    IClinicBeforeAndAfter,
    IClinicPaymentOptions,
    IClinicLanguage,
    IClinicExtraServices,
    setClinicStateFailure,
    selectClinicProfile
} from "meditrip-common-web";
import {
    updateClinicProfileAPI
} from "../../api/updateClinicProfile";

const getClinicProfileEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getClinicProfile.type),
        switchMap((action: PayloadAction<typeof IGetClinicProfile>): any => {
            return getClinicProfileAPI(
                action.payload.id
            ).pipe(
                mergeMap((profile) => {
                    const actions: any[] = [],
                        clinicProfile = getClinicProfilePayload((profile as any));

                    actions.push(setClinicProfileState(
                        clinicProfile.id,
                        clinicProfile.companyName,
                        clinicProfile.openHours,
                        clinicProfile.description,
                        clinicProfile.certificates,
                        clinicProfile.localAttractions,
                        clinicProfile.localization,
                        clinicProfile.extraServices,
                        clinicProfile.languages,
                        clinicProfile.paymentOptions,
                        clinicProfile.accreditations,
                        clinicProfile.staff,
                        clinicProfile.beforeAndAfter,
                        clinicProfile.clinicId
                    ));

                    actions.push(setClinicProfileStateLoading(false));
                    return of (...actions);
                }),
                catchError((error: any) => {
                    return of(
                        setClinicStateFailure(error.toString()),
                        addAlert({message: error.response['hydra:description']}),
                        setClinicProfileStateLoading(false)
                    )
                })
            )
        })
    );

const updateClinicProfileEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(updateClinicProfile.type),
        switchMap((action: PayloadAction<typeof IUpdateClinicProfile>): any => {
            const clinicProfile = selectClinicProfile(state$.value);
            const authToken = authTokenSelector(state$.value);
            const updatedProfile = getClinicProfileUpdatePayload(clinicProfile, (action.payload as any));

            return updateClinicProfileAPI(
                authToken,
                updatedProfile.id,
                updatedProfile.openHours,
                updatedProfile.description,
                updatedProfile.certificates,
                updatedProfile.localAttractions,
                updatedProfile.localization,
                updatedProfile.extraServices,
                updatedProfile.languages,
                updatedProfile.paymentOptions,
                updatedProfile.accreditations,
                updatedProfile.staff,
                updatedProfile.beforeAndAfter,
                updatedProfile.clinicId,
            ).pipe(
                mergeMap((profile) => {
                    const actions: any[] = [],
                        clinicProfile = getClinicProfilePayload(profile);

                    actions.push(setClinicProfileState(
                        clinicProfile.id,
                        clinicProfile.companyName,
                        clinicProfile.openHours,
                        clinicProfile.description,
                        clinicProfile.certificates,
                        clinicProfile.localAttractions,
                        clinicProfile.localization,
                        clinicProfile.extraServices,
                        clinicProfile.languages,
                        clinicProfile.paymentOptions,
                        clinicProfile.accreditations,
                        clinicProfile.staff,
                        clinicProfile.beforeAndAfter,
                        clinicProfile.clinicId
                    ));

                    actions.push(addAlert({message: "The clinic profile was updated."}));
                    actions.push(setClinicProfileStateLoading(false));
                    return of (...actions);
                }),
                catchError((error: any) => {
                    return of(
                        setClinicStateFailure(error.toString()),
                        addAlert({message: error.response['hydra:description']}),
                        setClinicProfileStateLoading(false)
                    )
                })
            )
        })
    );

function getClinicProfilePayload(profile: {[key: string]: any}) {
    let extraServices: typeof IClinicExtraServices[] = [],
        languagesList: typeof IClinicLanguage[] = [],
        paymentOptions: typeof IClinicPaymentOptions[] = [],
        mediaList: typeof IClinicBeforeAndAfter[] = [];

    profile.extraServices.map((service: any) => {
        let extraService: typeof IClinicExtraServices = {
            id: service.id,
            name: service.name
        };
        return extraServices.push(extraService);
    });

    profile.languages.map((item: any) => {
        let language: typeof IClinicLanguage = {
            id: item.id
        };
        return languagesList.push(language);
    });

    profile.paymentOptions.map((option: any) => {
        let paymentOption: typeof IClinicPaymentOptions = {
            id: option.id,
            name: option.name
        };
        return paymentOptions.push(paymentOption);
    });

    profile.beforeAndAfter.map((item: any) => {
        let media: typeof IClinicBeforeAndAfter = {
            id: item.id,
            contentUrl: item.contentUrl,
            public: item.public
        };
        return mediaList.push(media);
    });

    const clinicProfile = {
        id: profile.id,
        companyName: profile.clinic.companyName,
        openHours: profile.openHours,
        description: profile.description,
        certificates: profile.certificates,
        localAttractions: profile.localAttractions,
        localization: profile.localization,
        extraServices: extraServices,
        languages: languagesList,
        paymentOptions: paymentOptions,
        accreditations: profile.accreditations,
        staff: profile.staff,
        beforeAndAfter: mediaList,
        clinicId: profile.clinic.id
    };
    return clinicProfile;
}

function getClinicProfileUpdatePayload(clinicProfile: {[key: string]: any}, payload: {[key: string]: any}) {
    let extraServices: string[] = [];
    let languages: string[] = [];
    let paymentOptions: string[] = [];
    let mediaList: string[] = [];

    if (payload.extraServices) {
        payload.extraServices.map((service: any) => {
            if(service.value) {
                return extraServices.push(service.value);
            }

            if (service.id) {
                return extraServices.push(service.id);
            }
        });
    }

    if (payload.languages) {
        payload.languages.map((language: any) => {
            if (language.id){
                return languages.push(language.id);
            }

            if (language.value) {
                return languages.push(language.value);
            }
        });
    }

    if (payload.paymentOptions) {
        payload.paymentOptions.map((payment: any) => {
            if (payment.id) {
                return paymentOptions.push(payment.id);
            }

            if (payment.value) {
                return paymentOptions.push(payment.value);
            }
        });
    }

    if (payload.beforeAndAfter) {
        payload.beforeAndAfter.map((media: typeof IClinicBeforeAndAfter) => mediaList.push(media.id));
    }

    const updatedProfile = {
        id: payload.id,
        openHours: payload.openHours ? payload.openHours : [],
        description: payload.description ? payload.description : clinicProfile.description,
        certificates: payload.certificates ? payload.certificates : [],
        localAttractions: payload.localAttractions ? payload.localAttractions : clinicProfile.localAttractions,
        localization: payload.localization ? payload.localization : {lat: 10, long: 10}, // toDO localization cannot be null
        extraServices: extraServices,
        languages: languages,
        paymentOptions: paymentOptions,
        accreditations: payload.accreditations ? payload.accreditations : clinicProfile.accreditations,
        staff: payload.staff ? payload.staff : null,
        beforeAndAfter: mediaList,
        clinicId: payload.clinic ? payload.clinicId : clinicProfile.clinic
    };

    return updatedProfile;
}

const clinicProfileEpic = combineEpics(
    getClinicProfileEpic,
    updateClinicProfileEpic
);

export default clinicProfileEpic;
