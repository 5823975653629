import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import { createSpecialistAPI } from "./provider/specialistAPI";

export function createClinicSpecialistAPI(
    accessToken: string,
    specialist: {[key: string]: any}
): Observable<any> {
    const address = {
        addressLine1: specialist.account.address ? specialist.account.address.addressLine1 : null,
        addressLine2: specialist.account.address ? specialist.account.address.addressLine2 : null,
        addressLine3: specialist.account.address ? specialist.account.address.addressLine3 : null,
        postcode: specialist.account.address ? specialist.account.address.postcode : null,
        city: specialist.account.address ? specialist.account.address.city : null,
        country: specialist.account.address ? specialist.account.address.country : null,
    };

    const invoiceAddress = {
        taxId: specialist.account.invoiceAddress ? specialist.account.invoiceAddress.taxId : null,
        addressLine1: specialist.account.invoiceAddress ? specialist.account.invoiceAddress.addressLine1 : null,
        addressLine2: specialist.account.invoiceAddress ? specialist.account.invoiceAddress.addressLine2 : null,
        addressLine3: specialist.account.invoiceAddress ? specialist.account.invoiceAddress.addressLine3 : null,
        postcode: specialist.account.invoiceAddress ? specialist.account.invoiceAddress.postcode : null,
        city: specialist.account.invoiceAddress ? specialist.account.invoiceAddress.city : null,
        country: specialist.account.invoiceAddress ? specialist.account.invoiceAddress.country : null
    };

    return createSpecialistAPI.post(
        `create-doctor`,
        {
            login: specialist.login,
            plainPassword: specialist.plainPassword,
            account: {
                firstName: specialist.account.firstName,
                lastName: specialist.account.lastName,
                birthdate: specialist.account.birthdate,
                phone: specialist.account.phone,
                address: address,
                invoiceAddress: invoiceAddress,
                residency: specialist.account.residency,
            },
            description: specialist.description,
            education: specialist.education,
            yearsExperience: specialist.yearsExperience,
            languages: specialist.languages,
            certificates: specialist.certificates,
            membershipAndAwards: specialist.membershipAndAwards,
            treatmentTypes: specialist.treatments,
            specializations: specialist.specializations,
            clinics: specialist.clinics
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
