import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {RootState} from "../../../store/reducers";
import {authTokenSelector, Translation} from 'meditrip-common-web';
import ClinicDetails from "./PlanDetails";
import PlanDescription from "./PlanDescription";
import {retrieve} from '../../../actions/subscriptionDefinition/show';
import {
    retrievedSubscriptionDefinitionItemSelector,
    subscriptionDefinitionItemErrorSelector,
    subscriptionDefinitionItemLoadingSelector
} from "../../../store/selectors/subscriptionDefinitionItemSelectors";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";

interface IConnectedSubscriptionPlanViewProps {
    retrieved?: any;
    retrieve: any;
    readonly error: string;
    readonly isLoading: boolean;
    authToken: string;
}

interface IExternalSubscriptionPlanViewProps {}

interface ISubscriptionPlanViewProps extends IConnectedSubscriptionPlanViewProps,
    IExternalSubscriptionPlanViewProps,
    RouteComponentProps {
}

interface ISubscriptionPlanViewState {
    deleteModalShown: boolean;
    isLoading: boolean;
    subscriptionPlan: {[key: string]: any} | null;
}

class SubscriptionPlanView extends React.Component<ISubscriptionPlanViewProps, ISubscriptionPlanViewState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ISubscriptionPlanViewProps) {
        super(props);

        this.state = {
            deleteModalShown: false,
            isLoading: false,
            subscriptionPlan: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        const slug = this.props.match.params;
        this.props.retrieve(`subscription_definitions/${(slug as any).id}`, this.props.authToken);
    }

    componentWillUnmount() {}

    componentDidUpdate(
        prevProps: Readonly<ISubscriptionPlanViewProps>,
        prevState: Readonly<ISubscriptionPlanViewState>,
        snapshot?: any
    ): void {

        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'subscriptionPlans.view.title'}/>
                                {this.state.subscriptionPlan && this.state.subscriptionPlan.name}</div>
                            <div className="action-container">
                                <div onClick={() => this.props.history.goBack()} className="btn btn-no-outline">
                                    <span className="feather icon-chevron-left"/>
                                    <Translation text={"button.back"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <ClinicDetails subscriptionPlan={this.props.retrieved} isLoading={this.props.isLoading}/>
                    </div>

                    <div className="col-xl-6 col-md-12">
                        <PlanDescription subscriptionPlan={this.props.retrieved} isLoading={this.props.isLoading}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: RootState) => ({
        retrieved: retrievedSubscriptionDefinitionItemSelector(state),
        error: subscriptionDefinitionItemErrorSelector(state),
        isLoading: subscriptionDefinitionItemLoadingSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        retrieve
    }
)(SubscriptionPlanView);
