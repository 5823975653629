import React from "react";
import { connect } from "react-redux";
import {
  authTokenSelector,
  CustomCard,
  CustomCardType,
  CustomPagination,
  DateConverter,
  RestQueryParams,
  Translation,
  transformToPriceString
} from "meditrip-common-web";
import {RootState} from "../../../../store/reducers";
import AddInvoices from "./AddInvoices";
import { BasicModal } from "meditrip-common-web";
import {list, reset} from "../../../../actions/invoice/list";
import {
  invoiceListErrorSelector,
  invoiceListEventSourceSelector,
  invoiceListLoadingSelector,
  retrievedInvoiceListSelector
} from "../../../../store/selectors/invoiceListSelectors";
import {deleteInvoiceAPI} from "../../../../api/deleteInvoice";
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {of, Subscription} from "rxjs";
import {catchError, tap} from "rxjs/operators";


enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid'
}

interface IClinicInvoicesConnectedProps {
  readonly authToken: string;
  readonly retrieved: {[key: string]: any};
  readonly loading: boolean;
  readonly error: string | null;
  readonly eventSource: EventSource;
  readonly list: any;
  readonly reset: any;
}

interface IClinicInvoicesExternalProps {
  clinicId: string;
}

interface IClinicInvoicesProps extends
  IClinicInvoicesConnectedProps,
  IClinicInvoicesExternalProps {}

interface IClinicInvoicesState {
  selectedFile: any;
  isLoading: boolean;
  addInvoicesModalShown: boolean;
  deleteModalShown: boolean;
}

class ClinicInvoices extends React.Component<IClinicInvoicesProps, IClinicInvoicesState> {
  readonly subscriptions: Subscription[] = [];
  @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

  constructor(props: IClinicInvoicesProps) {
    super(props);

    this.state = {
      selectedFile: null,
      isLoading: false,
      addInvoicesModalShown: false,
      deleteModalShown: false,
    };

    fixInjectedProperties(this);
  }

  componentDidMount(): void {}

  componentDidUpdate(
    prevProps: Readonly<IClinicInvoicesProps>,
    prevState: Readonly<IClinicInvoicesState>,
    snapshot?: any
  ): void {}

  componentWillUnmount() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.props.reset(this.props.eventSource);
  }

  render() {
    return (
      <React.Fragment>
        <CustomCard showLocalLoader={this.props.loading}>
          <CustomCard.Header>
            <div className="d-flex justify-content-between align-items-center">
              <Translation text={'subscriptionPlans.clinicInvoices.title'}/>
              <button className="btn btn-theme"
                      onClick={() => this.toggleAddInvoicesModal()}>
                <Translation text={'subscriptionPlans.clinicInvoices.addInvoice'}/>
              </button>
            </div>
          </CustomCard.Header>
          <CustomCard.Body>
            {this.renderInvoiceList()}
            <CustomPagination retrieved={this.props.retrieved}
                              basePath="dashboard"
                              path="consultations"
                              provider={this.getClinicInvoices}
                              isPaginationLocal={true}/>
          </CustomCard.Body>
        </CustomCard>

        {this.state.addInvoicesModalShown &&
          <AddInvoices isInvoicesModalShown={this.state.addInvoicesModalShown}
                                toggleInvoicesModal={this.toggleAddInvoicesModal}
                                selectedFile={this.state.selectedFile}
                                clinicId={this.props.clinicId}
                                retrieveInvoices={this.getClinicInvoices}/>
        }

        {this.renderDeleteModal()}
      </React.Fragment>
    );
  }

  private toggleModal = () => {
    console.log('Toggle modal');
  };

  private renderInvoiceList = () => {
    if (!this.props.retrieved ||
        !this.props.retrieved['hydra:member'] ||
        !Array.isArray(this.props.retrieved['hydra:member'])) {
        return <p>There is no data available</p>;
    }

    return (
        <React.Fragment>
          <table className="data-table custom-table">
            <thead>
            <tr>
              <th><Translation text={'subscriptionPlans.clinicInvoices.table.name'}/></th>
              <th><Translation text={'subscriptionPlans.clinicInvoices.table.date'}/></th>
              <th><Translation text={'subscriptionPlans.clinicInvoices.table.price'}/></th>
              <th><Translation text={'subscriptionPlans.clinicInvoices.table.status'}/></th>
              <th className="text-center"/>
            </tr>
            </thead>
            <tbody>
            {this.renderTableRows()}
            </tbody>
          </table>
        </React.Fragment>
    )
  };

  private renderTableRows() {
    const rows: any[] = [];

    this.props.retrieved['hydra:member'].map((item: any, index: number) => {
      let status = item.paidAt ? PaymentStatus.PAID : PaymentStatus.UNPAID;
      return rows.push((
          <tr key={index}>
            <td>{item.name ? item.name : '---'}</td>
            <td><DateConverter date={item.issuedAt}/></td>
            <td>
              {item.valueAmount && item.valueCurrency ?
                  transformToPriceString(item.valueCurrency, item.valueAmount) :
                  '---'
              }
            </td>
            <td>
              <div className={`status-wrapper ${status}`}>
                <p className="status">
                  <Translation text={`subscriptionPlans.clinicInvoices.statuses.${status}`}/>
                </p>
              </div>
            </td>
            <td className="align-middle text-right">
              <button className="btn btn-action mr-2"
                      onClick={() => {
                        this.setState({selectedFile: item}, () => this.toggleAddInvoicesModal())
                      }}>
                <span className="feather icon-edit"/>
              </button>

              <button className="btn btn-action"
                      onClick={() => {
                        this.setState({selectedFile: item}, () => this.toggleDeleteModal())
                      }}>
                <span className="feather icon-trash-2"/>
              </button>
            </td>
          </tr>
      ))
    });
    return rows;
  }

  private renderDeleteModal = () => {
    return (
        <BasicModal isModalShown={this.state.deleteModalShown} closeModal={this.toggleDeleteModal}>
          <CustomCard type={CustomCardType.MODAL_CARD}>
            <CustomCard.Body>
              <div className="modal-header">
                <Translation text={'modal.confirm'}/>
                <button className="btn-modal-close" onClick={() => this.toggleDeleteModal()}>
                  <span className="feather icon-x"/>
                </button>
              </div>
              <div className="modal-body">
                <Translation text={'subscriptionPlans.clinicInvoices.deleteModal.title'}/>
              </div>
              <div className="modal-footer">
                <button className="btn btn-danger-outline mr-4"
                        onClick={() => this.toggleDeleteModal()}>
                  <Translation text={'button.no'}/>
                </button>
                <button className="btn btn-secondary-theme"
                        onClick={() => this.deleteFile()}>
                  <Translation text={'button.yes'}/>
                </button>
              </div>
            </CustomCard.Body>
          </CustomCard>
        </BasicModal>
    );
  };

  private deleteFile = () => {
    if (this.state.selectedFile) {
      return(
        this.subscriptions.push(
          deleteInvoiceAPI(this.props.authToken, this.state.selectedFile.id).pipe(
            tap(() => {
              this.toggleDeleteModal();
              this.alertManager.addAlert('Invoice was deleted');
              let queryParams = {
                itemsPerPage: 10,
                page: 1
              };
              this.getClinicInvoices(new RestQueryParams(queryParams));
            }),
            catchError((error: any) => {
              return of(this.alertManager.handleApiError(error))
            })
          ).subscribe()
        )
      )
    }
  };

  private toggleAddInvoicesModal = () => {
    if (this.state.addInvoicesModalShown) {
      this.setState({selectedFile: null});
    }

    this.setState({addInvoicesModalShown: !this.state.addInvoicesModalShown});
  };

  private getClinicInvoices = (searchParams: typeof RestQueryParams) => {
    searchParams = searchParams.add('clinic.id', this.props.clinicId);
    this.props.list(
        `invoices${searchParams.prepareQuery()}`,
        this.props.authToken
    );
  };

  private toggleDeleteModal = () => {
    if (this.state.deleteModalShown) {
      this.setState({selectedFile: null})
    }

    this.setState({
      deleteModalShown: !this.state.deleteModalShown,
    });
  };
}

export default connect(
  (state: RootState) => ({
    authToken: authTokenSelector(state),
    retrieved: retrievedInvoiceListSelector(state),
    loading: invoiceListLoadingSelector(state),
    error: invoiceListErrorSelector(state),
    eventSource: invoiceListEventSourceSelector(state),
  }),
  {
    list,
    reset,
  }
)(ClinicInvoices);
