import { combineEpics } from "redux-observable";
import {
  loginEpic,
  registrationEpic,
  resetPasswordEpic,
  renewAuthTokenEpic,
  accountEpic,
  changePasswordEpic,
  clinicEpic,
  reauthenticateEpic,
} from "meditrip-common-web";
import clinicStateEpic from "./clinicStateEpic";
import metadataEpic from "./metadataEpic";
import clinicProfileEpic from "./clinicProfileEpic";

export const rootEpic = combineEpics(
   loginEpic,
   registrationEpic,
   resetPasswordEpic,
   renewAuthTokenEpic,
   accountEpic,
   changePasswordEpic,
   clinicEpic,
   clinicStateEpic,
   reauthenticateEpic,
   metadataEpic,
   clinicProfileEpic
);
