import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {reset, retrieve} from '../../../actions/treatmentcategory/show';
import {del} from '../../../actions/treatmenttype/delete';
import {RootState} from "../../../store/reducers";
import {authTokenSelector, BasicModal, CustomCard, CustomCardType, Form, IFormConfig, Translation} from "meditrip-common-web";
import {
    retrievedTreatmentCategoryItemSelector,
    treatmentCategoryItemEventSourceSelector
} from "../../../store/selectors/treatmentCategoryItem";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {addTreatmentTypeAPI} from "../../../api/addTreatmentType";
import {list} from '../../../actions/treatmenttype/list';
import {
    retrievedTreatmentTypeListSelector,
    treatmentTypeListErrorSelector,
    treatmentTypeListLoadingSelector,
} from "../../../store/selectors/treatmentTypesListSelectors";
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {WithTranslation, withTranslation} from "react-i18next";
import {treatmentTypeFormConfig} from "./formConfig";

interface IConnectedTreatmentCategoryViewProps {
    retrieved?: any;
    retrieve: any;
    del: typeof del;
    eventSource?: any;
    reset: any;
    treatmentTypes: any;
    treatmentTypesLoading: boolean;
    treatmentTypesError: string | null;
    list: any;
    authToken: string;
}

interface IExternalTreatmentCategoryViewProps {
}

interface ITreatmentCategoryViewProps extends IConnectedTreatmentCategoryViewProps,
    IExternalTreatmentCategoryViewProps,
    RouteComponentProps,
    WithTranslation {
}

interface ITreatmentCategoryViewState {
    addModalShown: boolean;
    deleteModalShown: boolean;
    isLoading: boolean;
    isFormLoading: boolean;
    formIsValid: boolean;
    formConfig: typeof IFormConfig;
    treatmentCategoryTypes: { [key: string]: any }[];
    treatmentCategoryId: string | null;
    selectedTreatmentType: { [key: string]: any } | null;
}

class TreatmentCategoryView extends React.Component<ITreatmentCategoryViewProps, ITreatmentCategoryViewState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscription: Subscription | null = null;

    constructor(props: ITreatmentCategoryViewProps) {
        super(props);

        this.state = {
            addModalShown: false,
            deleteModalShown: false,
            isLoading: false,
            isFormLoading: false,
            formIsValid: true,
            formConfig: treatmentTypeFormConfig,
            treatmentCategoryTypes: [],
            treatmentCategoryId: null,
            selectedTreatmentType: null
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        const slug = this.props.match.params;
        this.setState({treatmentCategoryId: (slug as any).id});
        this.props.retrieve(`treatment_categories/${(slug as any).id}`, this.props.authToken);

        this.props.list(
            `treatment-types?page=1&itemsPerPage=1000&treatmentCategory.id[]=${(slug as any).id}`,
            this.props.authToken
        );
    }

    componentDidUpdate(
        prevProps: Readonly<ITreatmentCategoryViewProps>,
        prevState: Readonly<ITreatmentCategoryViewState>,
        snapshot?: any
    ): void {
        if (this.props.treatmentTypes !== prevProps.treatmentTypes) {
            let treatmentTypes = this.props.treatmentTypes['hydra:member'].filter((item: any) => {
                return item.treatmentCategory.id === this.state.treatmentCategoryId;
            });
            this.setState({treatmentCategoryTypes: treatmentTypes});
        }

        if (this.props.treatmentTypesError !== prevProps.treatmentTypesError) {
            this.alertManager.handleApiError(this.props.treatmentTypesError);
        }
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.props.eventSource) {
            this.props.reset(this.props.eventSource);
        }
    }

    render() {
        const item = this.props.retrieved,
            categoryName = item ? `: ${item.name}` : '';

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-8">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'treatmentCategories.category.title'}/>{categoryName}
                            </div>
                            <div className="actionContainer">
                                <Link to={`/dashboard/treatment-categories`} className="btn btn-no-outline">
                                    <span className="feather icon-chevron-left"/>
                                    <Translation text={'button.back'}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-8">
                        <CustomCard showLocalLoader={this.props.treatmentTypesLoading}>
                            <CustomCard.Header>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6>Treatment Types</h6>
                                    <button className="btn btn-theme"
                                            type="button"
                                            onClick={() => this.toggleAddModal()}>
                                        <Translation text={'treatmentCategories.category.btnAdd'}/>
                                    </button>
                                </div>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                <div className="mr-4 ml-4">
                                    {this.renderTreatmentTypesTable()}
                                </div>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>


                <BasicModal isModalShown={this.state.addModalShown} closeModal={this.closeAddModal}>
                    <CustomCard showLocalLoader={this.state.isFormLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'treatmentCategories.addTreatmentTypeModal.title'}/>
                                <button className="btn-modal-close" onClick={() => this.toggleAddModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Form config={this.state.formConfig}
                                      submitForm={this.addTreatmentType}
                                      controlName={'addTreatmnetCategoryForm'}/>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>

                <BasicModal isModalShown={this.state.deleteModalShown} closeModal={this.closeDeleteModal}>
                    <CustomCard type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'modal.confirm'}/>
                                <button className="btn-modal-close" onClick={() => this.toggleDeleteModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Translation text={'treatmentCategories.deleteTreatmentTypeModal.title'}/>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-danger-outline mr-4"
                                        onClick={() => this.toggleDeleteModal()}>
                                    <Translation text={'button.no'}/>
                                </button>
                                <button className="btn btn-secondary-theme"
                                        onClick={() => this.deleteTreatmentCategoryType()}>
                                    <Translation text={'button.yes'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private renderTreatmentTypesTable() {
        if (!this.state.treatmentCategoryTypes || !Array.isArray(this.state.treatmentCategoryTypes) ||
            !this.state.treatmentCategoryTypes.length) {
            return (
                <p>
                    <Translation text={'insuranceClinicReport.noReportData'}/>
                </p>
            );
        }

        return (
            <table className="data-table">
                <thead>
                <tr>
                    <th>
                        <Translation text={'treatmentCategories.treatmentTypesTable.headers.treatmentType'}/>
                    </th>
                    <th colSpan={2}/>
                </tr>
                </thead>
                <tbody>
                {this.renderTableRows()}
                </tbody>
            </table>
        )
    }

    private renderTableRows() {
        if (!this.state.treatmentCategoryTypes) {
            return;
        }

        const rows: any[] = [],
            list = this.state.treatmentCategoryTypes;

        list.map((item: any) => {
            return rows.push(
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td className="text-right">
                        <button className="btn btn-action"
                                onClick={() => this.toggleDeleteModal(item)}>
                            <span className="feather icon-trash"/>
                        </button>
                    </td>
                </tr>
            )
        });

        return rows;
    }

    private toggleAddModal = () => {
        this.setState({addModalShown: !this.state.addModalShown});
    };

    private toggleDeleteModal = (item?: { [key: string]: any }) => {
        if (item) {
            this.setState({selectedTreatmentType: item})
        }

        this.setState({
            deleteModalShown: !this.state.deleteModalShown,
        });
    };
    private closeAddModal = () => {
        return this.setState({addModalShown: false});
    };
    private closeDeleteModal = () => {
        return this.setState({deleteModalShown: false});
    };

    private deleteTreatmentCategoryType = () => {
        this.props.del(this.state.selectedTreatmentType, this.props.authToken);
        let treatmentTypes = this.state.treatmentCategoryTypes.filter((el: any) => {
            if (this.state.selectedTreatmentType) {
                return el.id !== this.state.selectedTreatmentType.id;
            }
            return [];
        });

        this.setState({
            deleteModalShown: false,
            treatmentCategoryTypes: treatmentTypes,
            selectedTreatmentType: null,
        });

        this.alertManager.addAlert('Treatment type was deleted');
    };

    private addTreatmentType = (event: any, value: any, valid: boolean, touched: boolean): void => {
        if (!valid || !touched) {
            return;
        }
        this.setState({isFormLoading: true});

        this.subscription = this.handleAddTreatmentTypeAPI(value.name).subscribe();
    };

    private handleAddTreatmentTypeAPI(value: string) {
        return addTreatmentTypeAPI(
            this.props.authToken,
            this.props.retrieved.id,
            value,
            []
        ).pipe(
            map((treatmentType: any) => {
                let treatmentTypes = this.state.treatmentCategoryTypes;
                treatmentTypes.push(treatmentType);
                this.alertManager.addAlert('Treatment type was successfully added');
                this.setState({
                    isFormLoading: false,
                    treatmentCategoryTypes: treatmentTypes,
                    addModalShown: false
                });
            }),
            catchError((error: any) => {
                const message = error ? error.message : 'Something went wrong';
                this.setState({isFormLoading: false});
                return of(this.alertManager.addAlert(message));
            })
        );
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedTreatmentCategoryItemSelector(state),
        eventSource: treatmentCategoryItemEventSourceSelector(state),
        authToken: authTokenSelector(state),
        treatmentTypes: retrievedTreatmentTypeListSelector(state),
        treatmentTypesLoading: treatmentTypeListLoadingSelector(state),
        treatmentTypesError: treatmentTypeListErrorSelector(state)
    }),
    {
        retrieve,
        del,
        reset,
        list
    }
)(TreatmentCategoryView));
