import React from 'react';
import { Route } from 'react-router-dom';
import App from "../App";
import PanelHost from "../components/PanelHost";
import {
  AuthPanel, AuthType,
  Maintenance,
  UserRole,
  PrivateRoute
} from "meditrip-common-web";
import {registrationFormConfig} from "./registrationFormConfig";

export default [
  <Route path="/" component={App} exact key="home" />,
  // <Route path="/dashboard/:slug" component={ViewHost} key="dashboard" />,
  <PrivateRoute path="/dashboard/" component={PanelHost} key="dashboard" />,

  // Auth routes
  <Route path="/auth/login" component={() => <AuthPanel userRole={UserRole.ADMIN} authType={AuthType.LOGIN}/>} exact key="login"/>,
  <Route path="/auth/register"
         component={() =>  <AuthPanel userRole={UserRole.ADMIN} authType={AuthType.REGISTER} formConfig={registrationFormConfig}/>}
         exact key="register"/>,
  <Route path="/auth/reset-password"
         component={() =>  <AuthPanel userRole={UserRole.ADMIN} authType={AuthType.RESET_PASSWORD}/>} exact key="reset-password"/>,
  <Route path="/auth/new-password/:slug" component={() =>  <AuthPanel userRole={UserRole.ADMIN} authType={AuthType.NEW_PASSWORD}/>} exact key="new-password"/>,

  // Inquiry routes
  // <Route path="/dashboard/inquiries" component={List} strict key="list" />,
  // <Route path="/dashboard/inquiries/:page" component={List} strict key="page" />,
  // <Route path="/inquiry/:id" component={Show} exact key="show" />,
  // <Route path="/inquiries/edit/:id" component={Update} exact key="update" />,
  <Route key="not-found" component={Maintenance} />
];
