import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
    ValidationRules
} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {addSubscriptionFormConfig} from "./formConfig";
import {addSubscriptionDefinitionAPI, IAddSubscriptionPayload} from "../../../api/addSubscriptionDefinition";
import {list} from "../../../actions/subscriptionDefinition/list";


interface IConnectedAddSubscriptionPlanProps {
    readonly authToken: string;
    readonly list: any;
}

interface IAddSubscriptionPlanProps extends IConnectedAddSubscriptionPlanProps,
    RouteComponentProps,
    WithTranslation {
    addSubscriptionModalShown: boolean;
    closeSubscriptionModal: any;
    toggleSubscriptionModal: any;
    selectedSubscription: {[key: string]: any} | null;
}

interface IAddSubscriptionPlanState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    value: any;
}

class AddSubscriptionPlan extends React.Component<IAddSubscriptionPlanProps, IAddSubscriptionPlanState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IAddSubscriptionPlanProps) {
        super(props);

        this.state = {
            formConfig: addSubscriptionFormConfig,
            isFormValid: true,
            isLoading: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        if (this.props.selectedSubscription) {
            this.changeFormControlValues(this.props.selectedSubscription);
        } else {
            this.updateButtonText('subscriptionPlans.createPlan');
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IAddSubscriptionPlanProps>,
        prevState: Readonly<IAddSubscriptionPlanState>,
        snapshot?: any
    ): void {

    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.addSubscriptionModalShown} closeModal={this.props.closeSubscriptionModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                {!this.props.selectedSubscription ?
                                    <Translation text={'subscriptionPlans.newPlan'}/> :
                                    <Translation text={'subscriptionPlans.duplicatePlan'} />
                                }

                                <button className="btn-modal-close" onClick={() => {
                                    this.setState({value: null});
                                    this.props.toggleSubscriptionModal();
                                }}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body aftercare-event-modal">
                                <Form config={this.state.formConfig}
                                      onValueStateChange={this.onValueStateChange}
                                      // onValidationStateChange={this.onValidationStateChange}
                                      value={this.state.value}
                                      controlName={'addSubscriptionPlanForm'}
                                      submitForm={this.addSubscription}/>

                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        Object.keys(value).map((key: string) => {
            if (key === 'onlineConsultation' &&
                value[key] !== null &&
                value[key] !== this.state.value?.onlineConsultation) {
                this.updateOnlineConsultationControls(value[key])
            }

            if (key === 'clinicWidget' && value[key] !== null) {
                this.updateWidgetAmountControl(value[key]);
            }
        });

        this.setState({value: value});
    };

    private addSubscription = () => {
        this.setState({isLoading: true});
        const value = this.state.value,
            payload: IAddSubscriptionPayload = {
                name: value.planName,
                description: '',
                price: {
                    amount: (Number(value.planPrice) * 100).toString(),
                    currency: {
                        code: 'EUR'
                    }
                },
                duration: 30,
                subscriptionRestrictions: {
                    consultationTimeLimit: value.telemedicineHours ? Number(value.telemedicineHours) * 60 : 0,
                    clinicCalendarWidgetAvailable: value.clinicWidget,
                    appCalendarWidgetAvailable: value.meditripWidget,
                    treatmentPlanEnabled: true,
                    calendarCountLimit: value.widgetAmount ? Number(value.widgetAmount) : 0
                }
            };

        this.subscriptions.push(this.handleAddSubscriptionAPI(payload).subscribe());
    };

    private handleAddSubscriptionAPI(payload: IAddSubscriptionPayload, ) {
        this.setState({isLoading: true});
        const isPlanActive = this.state.value.isPlanActive;

        return addSubscriptionDefinitionAPI(
            this.props.authToken, payload,
            true,
            null,
            isPlanActive
        ).pipe(
            map(() => {
                const searchParams = this.props.location.search;
                this.props.list(
                    `subscription_definitions${searchParams}&public=true`,
                    this.props.authToken
                );

                this.setState({isLoading: false, value: null});
                this.props.toggleSubscriptionModal();
                this.alertManager.addAlert("Subscription plan was successfully added");
            }),
            catchError((error: any) => {
                return of(this.alertManager.handleApiError(error));
            })
        )
    }

    private changeFormControlValues(plan: {[key: string]: any}) {
        const subscriptionDetails = plan.subscriptionDetails,
            subscriptionRestrictions = subscriptionDetails.subscriptionRestrictions;

        this.setState({
            value: {
                // aftercare: subscriptionRestrictions.treatmentPlanEnabled,
                meditripWidget: subscriptionRestrictions.appCalendarWidgetAvailable,
                clinicWidget: subscriptionRestrictions.clinicCalendarWidgetAvailable,
                // isPlanActive: plan.active,
                onlineConsultation: subscriptionRestrictions.appCalendarWidgetAvailable ||
                    subscriptionRestrictions.clinicCalendarWidgetAvailable ||
                    subscriptionRestrictions.consultationTimeLimit > 0,
                planName: subscriptionDetails.name,
                planPrice: subscriptionDetails.price.amount / 100,
                // reviews: plan.reviews,
                telemedicineHours: subscriptionRestrictions.consultationTimeLimit / 60,
                widgetAmount: subscriptionRestrictions.calendarCountLimit
            },
            isFormValid: true
        });
        this.updateButtonText('subscriptionPlans.duplicatePlan');
    }

    private updateButtonText = (btnText: string) => {
        const updatedFormConfig = Object.assign({}, addSubscriptionFormConfig);
        updatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'submitButton') {
                        control.controls[key].btnText = btnText;
                    }
                });
            }

            return control;
        });

        return this.setState({formConfig: updatedFormConfig});
    };

    private updateOnlineConsultationControls(value: string): void {
        const cloneFormConfig = Object.assign({}, this.state.formConfig);
        cloneFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (value) {
                        let requiredField = [
                            { name: ValidationRules.IS_REQUIRED },
                        ];
                        if (key === 'telemedicineHours') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'clinicWidget') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'meditripWidget') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                    } else {
                        if (key === 'telemedicineHours') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'clinicWidget') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'meditripWidget') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                    }
                });
            }

            return control;
        });

        this.setState({
            formConfig: cloneFormConfig,
        });
    }

    private updateWidgetAmountControl(value: string): void {
        const cloneFormConfig = Object.assign({}, this.state.formConfig);
        cloneFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (value) {
                        let requiredField = [
                            { name: ValidationRules.IS_REQUIRED },
                        ];
                        if (key === 'widgetAmount') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                    } else {
                        if (key === 'widgetAmount') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                    }
                });
            }

            return control;
        });

        this.setState({
            formConfig: cloneFormConfig,
        });
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        list
    }
)(withRouter(AddSubscriptionPlan)));
