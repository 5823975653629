import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";

export interface IAddSubscriptionPayload {
    name: string,
    description: string | null,
    price: {
        amount: string,
        currency: {
            code: string
        }
    },
    duration: number,
    subscriptionRestrictions: {
        consultationTimeLimit: number | null,
        clinicCalendarWidgetAvailable: boolean,
        appCalendarWidgetAvailable: boolean,
        treatmentPlanEnabled: boolean,
        calendarCountLimit: number | null
    }
}

export function addSubscriptionDefinitionAPI(
    authToken: string,
    subscriptionDetails: IAddSubscriptionPayload,
    isPublic: boolean,
    itemOrder: number | null,
    active: boolean
): Observable<any> {
    return treatmentTypeAPI.post(
        `subscription_definitions`,
        {
            subscriptionDetails: subscriptionDetails,
            public: isPublic,
            itemOrder: itemOrder,
            active: active
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
