import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {clinicAPI} from "./provider/clinicAPI";

export function getClinicSpecialistsAPI(
    accessToken: string,
    clinicId: string): Observable<any> {
    return clinicAPI.get(
        `clinics/${clinicId}/doctors`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
