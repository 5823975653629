export default {
  items: [
    {
      id: 'Dashboard',
      title: 'Admin',
      type: 'group',
      icon: '',
      children: [
      // {
      //     id: 'dashboard',
      //     title: 'Dashboard',
      //     type: 'item',
      //     url: '/dashboard',
      //     icon: 'feather icon-home',
      // },
        {
          id: 'clinics',
          title: 'Clinics',
          type: 'item',
          url: '/dashboard/clinics?page=1&itemsPerPage=10',
          icon: 'feather icon-activity',
        },
        {
          id: 'consultation',
          title: 'Consultations',
          type: 'item',
          icon: 'feather icon-monitor',
          url: '/dashboard/consultations?page=1&itemsPerPage=10',
        },
        {
          id: 'treatmentCategories',
          title: 'Treatment Categories',
          type: 'item',
          url: '/dashboard/treatment-categories?page=1&itemsPerPage=10',
          icon: 'feather icon-layers',
        },
        {
            id: 'treatmentPlanner',
            title: 'Treatment Planner',
            type: 'collapse',
            icon: 'feather icon-smartphone',
            children: [
                {
                    id: 'predefinedRecommendations',
                    title: 'Predefined Events',
                    type: 'item',
                    url: '/dashboard/recommendations'
                }
            ]
        },
        {
          id: 'subscriptionPlans',
          title: 'Subscription Plans',
          type: 'item',
          icon: 'feather icon-box',
          url: '/dashboard/subscription-plans?page=1&itemsPerPage=10'
        }
      ]
    }
  ]
}
