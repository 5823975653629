import React from 'react';
import './App.scss';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import {RootState} from "./store/reducers";
import {isAuthenticatedSelector} from "meditrip-common-web";

interface IConnectedAppProps {
  isAuthenticated: boolean;
}

interface IAppProps extends
  IConnectedAppProps,
  RouteComponentProps {}

interface IAppState {
  isAuthenticated: boolean;
}

class App extends React.Component<IAppProps, IAppState>{
  constructor(props: IAppProps) {
    super(props);
     this.state ={
       isAuthenticated: this.props.isAuthenticated
     }
  }

  componentDidUpdate(
      prevProps: Readonly<IAppProps>,
      prevState: Readonly<IAppState>,
      snapshot?: any
  ): void {
      if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
          this.setState({isAuthenticated: this.props.isAuthenticated});
      }
  }


    render() {
      const path = this.state.isAuthenticated ? '/dashboard/clinics' : '/auth/login';
        return (
          <div className="App">
            <Redirect push to={path}/>
          </div>
        );
  }
}

export default connect(
    (state: RootState) => ({
      isAuthenticated: isAuthenticatedSelector(state)
    })
)(withRouter(App));
