import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'meditrip-common-web';

export const generalInformationFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'description',
            class: 'row',
            controls: {
                description: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Overview',
                    label: 'Overview',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            },
        },
        // {
        //   controlType: 'group',
        //   key: 'location',
        //   class: 'row',
        //   controls: {
        //     location: {
        //       controlType: 'control',
        //       defaultValue: '',
        //       formControlType: FormControlType.TEXTAREA,
        //       validationRules: [
        //         { name: ValidationRules.IS_REQUIRED },
        //       ],
        //       placeholder: 'Location',
        //       label: "Location",
        //       type: InputType.TEXT,
        //       hostClass: 'col-xl-12',
        //     }
        //   }
        // },
        {
            controlType: 'group',
            key: 'local_attractions',
            class: 'row',
            controls: {
                localAttractions: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Local Attractions',
                    label: 'Local Attractions',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            }
        },
        // {
        //   controlType: 'group',
        //   key: 'maps',
        //   class: 'row',
        //   controls: {
        //     maps: {
        //       controlType: 'control',
        //       defaultValue: '',
        //       formControlType: FormControlType.INPUT,
        //       validationRules: [
        //         { name: ValidationRules.IS_REQUIRED },
        //       ],
        //       placeholder: 'Maps',
        //       label: "Maps",
        //       type: InputType.TEXT,
        //       hostClass: 'col-xl-12',
        //     }
        //   }
        // },
        {
            controlType: 'group',
            key: 'extra_services',
            class: 'row',
            controls: {
                extraServices: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectOptions: [],
                    inputDataMapper: (data: any) => {
                        if (!data || !Array.isArray(data)) {
                            return [];
                        }

                        return data.map((service: any) => ({
                            value: service.id,
                            label: service.name,
                        }));
                    },
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'No extra services selected',
                    label: 'Extra Services',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            }
        },
        {
            controlType: 'group',
            key: 'general_information_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2 pr-0',
                    defaultValue: null,
                    btnText: 'Save',
                    defaultStyles: 'btn btn-primary'
                },
            },
        },
    ]
};
