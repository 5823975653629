import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {clinicAPI} from "./provider/clinicAPI";
import {
    IClinicOpenHours,
    IClinicCertificates,
    IClinicLocalization,
    IClinicExtraServices,
    IClinicLanguage,
    IClinicPaymentOptions,
    IClinicBeforeAndAfter
} from "meditrip-common-web";

export function updateClinicProfileAPI(
    accessToken: string,
    profileId: string,
    openHours: typeof IClinicOpenHours[] | null,
    description: string | null,
    certificates: typeof IClinicCertificates[] | null,
    localAttractions: string | null,
    localization: typeof IClinicLocalization | null,
    extraServices: typeof IClinicExtraServices[] | null,
    languages: typeof IClinicLanguage[] | null,
    paymentOptions: typeof IClinicPaymentOptions[] | null,
    accreditations: string | null,
    staff: string | null,
    beforeAndAfter: typeof IClinicBeforeAndAfter[] | null,
    clinic: string
): Observable<any> {
    // let payload: {[key: string]: any} = {};
    // openHours ? payload['openHours'] = openHours : null;
    // description ? payload['description'] = description : null;
    // certificates ? payload['certificates'] = certificates : null;
    // localAttractions ? payload['localAttractions'] = localAttractions : null;
    // localization ? payload['localization'] = localization : null;
    // extraServices ? payload['extraServices'] = extraServices : null;
    // languages ? payload['languages'] = languages : null;
    // paymentOptions ? payload['reference'] = paymentOptions : null;
    // accreditations ? payload['accreditations'] = accreditations : null;
    // staff ? payload['staff'] = staff : null;
    // beforeAndAfter ? payload['beforeAndAfter'] = beforeAndAfter : null;
    // clinic ? payload['clinic'] = clinic : null;

    return clinicAPI.put(
        `profile/${profileId}/update`,
        // payload,
        {
            openHours,
            description,
            certificates,
            localAttractions,
            localization,
            extraServices,
            languages,
            paymentOptions,
            accreditations,
            staff,
            beforeAndAfter,
            clinic
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}

