import React from "react";
import { connect } from "react-redux";
import {authTokenSelector, CustomCard, Translation} from "meditrip-common-web";
import {RootState} from "../../../../store/reducers";
import RichTextComponent from "./RichTextEditor";
import styles from "./styles.module.scss";
import {of, Subscription} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {IAddSubscriptionPayload} from "../../../../api/addSubscriptionDefinition";
import {updateSubscriptionDefinitionAPI} from "../../../../api/updateSubscriptionDefinition";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";

interface IPlanDescriptionConnectedProps {
  readonly authToken: string;
}

interface IPlanDescriptionExternalProps {
  subscriptionPlan: {[key: string]: any} | null;
  isLoading: boolean;
}

interface IPlanDescriptionProps extends
  IPlanDescriptionConnectedProps,
  IPlanDescriptionExternalProps {}

interface IPlanDescriptionState {}

class PlanDescription extends React.Component<IPlanDescriptionProps, IPlanDescriptionState> {
  readonly subscriptions: Subscription[] = [];
  @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
  private elRef: React.RefObject<any>;

  constructor(props: IPlanDescriptionProps) {
    super(props);

    this.state = {};

    this.elRef = React.createRef();
    fixInjectedProperties(this);
  }

  componentDidMount(): void {}

  componentDidUpdate(
    prevProps: Readonly<IPlanDescriptionProps>,
    prevState: Readonly<IPlanDescriptionState>,
    snapshot?: any
  ): void {}

  componentWillUnmount() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.props.isLoading}>
        <CustomCard.Header>
          <div className="d-flex justify-content-between align-items-center">
            <Translation text={'subscriptionPlans.view.description'}/>
          </div>
        </CustomCard.Header>
        <CustomCard.Body>

          <RichTextComponent ref={this.elRef}
                             value={this.props.subscriptionPlan?.subscriptionDetails?.description}
          />

          <div className={styles.btnContainer}>
            <button className="btn btn-theme"
                    onClick={() => this.updateDescription()}>
              <Translation text={'subscriptionPlans.view.addDescription'}/>
            </button>
          </div>
        </CustomCard.Body>
      </CustomCard>
    );
  }

  private updateDescription = () => {
    if (this.elRef && this.props.subscriptionPlan) {
      let rteValue: string = this.elRef.current.rteObj.value;
      // Or you can use the public method getContent to retrieve the RTE content.
      // let rteValueContent: string = this.elRef.current.rteObj.getContent();
      // To fetch the RichTextEditor’s text content, use the textContent property of RTE EditPanel.
      // let rteValueTextContent: string = this.elRef.current.rteObj.contentModule.getEditPanel().textContent;

      const plan = this.props.subscriptionPlan,
          planDetails = plan.subscriptionDetails,
          planRestrictions = planDetails.subscriptionRestrictions;

      const payload: IAddSubscriptionPayload = {
        name: planDetails.name,
        description: rteValue,
        price: {
          amount: planDetails.price.amount,
          currency: {
            code: planDetails.price.currency.code
          }
        },
        duration: 30,
        subscriptionRestrictions: {
          consultationTimeLimit: planRestrictions.consultationTimeLimit,
          clinicCalendarWidgetAvailable: planRestrictions.clinicCalendarWidgetAvailable,
          appCalendarWidgetAvailable: planRestrictions.appCalendarWidgetAvailable,
          treatmentPlanEnabled: planRestrictions.treatmentPlanEnabled,
          calendarCountLimit: planRestrictions.calendarCountLimit
        }
      };

      this.subscriptions.push(updateSubscriptionDefinitionAPI(
          this.props.authToken,
          this.props.subscriptionPlan.id,
          payload,
          plan.public,
          plan.itemOrder,
          plan.active).pipe(
          map(() => {
            this.alertManager.addAlert("Subscription plan details were updated");
          }),
          catchError((error: any) => {
            return of(this.alertManager.handleApiError(error));
          })
      ).subscribe());
    }
  }
}

export default connect(
  (state: RootState) => ({
    authToken: authTokenSelector(state)
  }),
  {}
)(PlanDescription);
