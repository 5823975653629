import { Container } from 'inversify';
import getDecorators, { fixInjectedProperties } from './decorators';
import {FormHandleChangeService} from "meditrip-common-web";
import {AftercareMapperService, AlertManagerService} from "../service";

const container = new Container();

container.bind<typeof FormHandleChangeService>('FormHandleChangeService').to(FormHandleChangeService).inSingletonScope();
container.bind<AlertManagerService>('AlertManagerService').to(AlertManagerService).inSingletonScope();
container.bind<AftercareMapperService>('AftercareMapperService').to(AftercareMapperService).inSingletonScope();

const { lazyInject } = getDecorators(container);

export { container, lazyInject, fixInjectedProperties };
