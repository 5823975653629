import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {clinicAPI} from "./provider/clinicAPI";

export function createClinicAPI(
    // accountId: string,
    accessToken: string,
    clinic: {[key: string]: any}
): Observable<any> {
    return clinicAPI.post(
        `clinics`,
        {
            login: clinic.account.login,
            plainPassword: clinic.account.plainPassword,
            account: {
                firstName: clinic.account.firstName,
                lastName: clinic.account.lastName,
                birthdate: clinic.account.birthdate,
                phone: clinic.account.phone,
                address: {
                    addressLine1: clinic.account.address.addressLine1,
                    addressLine2: clinic.account.address.addressLine2,
                    addressLine3: clinic.account.address.addressLine3,
                    postcode: clinic.account.address.postcode,
                    city: clinic.account.address.city,
                    country: clinic.account.address.country,
                },
                invoiceAddress: {
                    taxId: clinic.account.invoiceAddress.taxId,
                    addressLine1: clinic.account.invoiceAddress.addressLine1,
                    addressLine2: clinic.account.invoiceAddress.addressLine2,
                    addressLine3: clinic.account.invoiceAddress.addressLine3,
                    postcode: clinic.account.invoiceAddress.postcode,
                    city: clinic.account.invoiceAddress.city,
                    country: clinic.account.invoiceAddress.country
                },
                residency: clinic.account.residency,
            },
            companyName: clinic.companyName,
            reference: clinic.reference,
            isMiddleman: clinic.isMiddleMan,
            feePercent: clinic.feePercent
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
