import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {clinicAPI} from "./provider/clinicAPI";

export function getSubscriptionDefinitionsAPI(
    accessToken: string): Observable<any> {
    return clinicAPI.get(
        `subscription_definitions?itemsPerPage=100`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
