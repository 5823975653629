import React from "react";
import { RootState } from "../../../../store/reducers";
import { connect } from "react-redux";
import {
  CustomCard,
  Form,
  Translation,
  IClinicProfileState,
  IFormConfig,
  clinicProfileLoadingSelector,
  updateClinicProfile
} from "meditrip-common-web";
import { generalInformationFormConfig} from "./generalInformationFormConfig";
import {IMetadataCategory, retrieveExtraServices} from "../../../../store/reducers/metadataSlice";
import {extraServicesSelector} from "../../../../store/selectors/metadataSelectors";

interface IGeneralInformationConnectedProps {
  readonly isLoading: boolean;
  readonly extraServices: IMetadataCategory[];
  readonly retrieveExtraServices: typeof retrieveExtraServices;
  readonly updateClinicProfile: typeof updateClinicProfile;
}

interface IGeneralInformationExternalProps {
  clinicProfile: typeof IClinicProfileState;
  authToken: string;
}

interface IGeneralInformationProps extends
  IGeneralInformationConnectedProps,
  IGeneralInformationExternalProps {}

interface IGeneralInformationState {
  isLoading: boolean;
  formConfig: typeof IFormConfig;
  value: any;
}

class GeneralInformation extends React.Component<IGeneralInformationProps, IGeneralInformationState> {
  constructor(props: IGeneralInformationProps) {
    super(props);
      this.state = {
        isLoading: true,
        formConfig: generalInformationFormConfig,
        value: null
      };
    }

  componentDidMount(): void {
    this.props.retrieveExtraServices(this.props.authToken);
    if(this.props.clinicProfile) {
      this.updateFormFromState();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IGeneralInformationProps>,
    prevState: Readonly<IGeneralInformationState>,
    snapshot?: any
  ): void {
    if(this.props.isLoading !== prevProps.isLoading &&
        !this.props.isLoading) {
      this.setState({isLoading: false})
    }

    if(this.props.extraServices !== prevProps.extraServices) {
      this.setExtraServices();
    }

    if(this.props.clinicProfile !== prevProps.clinicProfile) {
      this.updateFormFromState();
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
          <Translation text={'profile.generalInformation.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
            <Form config={this.state.formConfig}
                  submitForm={this.changeData}
                  controlName={'generalInformationForm'}
                  value={this.state.value}/>

        </CustomCard.Body>
      </CustomCard>
    );
  }

  private setExtraServices = () => {
    if (!this.props.extraServices) {
      return;
    }

    generalInformationFormConfig.controls.map((control: any) => {
      if (control.hasOwnProperty("controls")) {
        Object.keys(control.controls).map((key: string) => {
          if (key === 'extraServices') {
            control.controls[key].multiselectOptions = this.props.extraServices;
          }
        });
      }

      return control;
    });

    this.setState({formConfig: generalInformationFormConfig});
  };

  private mapAccountToFormData(clinicProfile: any): any {
    return {
      description: clinicProfile.description,
      // location: clinicProfile.localization,
      localAttractions: clinicProfile.localAttractions,
      // maps: clinicProfile.localization,
      extraServices: clinicProfile.extraServices
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.clinicProfile)
    })
  }

  private changeData = (event: any, value: any, valid: boolean, touched: boolean): void => {
    if (!valid || !touched) {
      return;
    }

    this.setState({isLoading: true});
    this.props.updateClinicProfile(
        this.props.clinicProfile.id,
        null,
        null,
        value.description,
        null,
        value.localAttractions,
        null,
        value.extraServices,
        null,
        null,
        null,
        null,
        null,
        null
    )
  }
}

export default connect(
  (state: RootState) => ({
    isLoading: clinicProfileLoadingSelector(state),
    extraServices: extraServicesSelector(state)
  }),
  {
    retrieveExtraServices,
    updateClinicProfile
  }
)(GeneralInformation);
