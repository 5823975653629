import React from 'react';
import {connect} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {reset, retrieve} from '../../../actions/clinic/show';
import {del} from '../../../actions/clinic/delete';
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    getClinicProfile,
    IClinicProfileState,
    selectClinicProfile, Translation,
} from 'meditrip-common-web';
import {
    clinicItemDeletedSelector,
    clinicItemDeleteErrorSelector,
    clinicItemDeleteLoadingSelector,
    clinicItemErrorSelector,
    clinicItemEventSourceSelector,
    clinicItemLoadingSelector,
    retrievedClinicItemSelector
} from "../../../store/selectors/clinicItemSelectors";
import {addSpecialist} from '../../../store/reducers/specialistSlice';
import GeneralInformation from "./GeneralInformation";
import ClinicDetails from "./ClinicDetails";
import ClinicSpecialists from "./ClinicSpecialists";
import ClinicPhotos from "./ClinicPhotos";
import ClinicTreatments from "./ClinicTreatments";
// import ClinicCommission from "./ClinicCommission";
import ClinicSubscriptionPlan from "./ClinicSubscriptionPlan";
import ClinicInvoices from "./ClinicInvoices";

interface IConnectedClinicViewProps {
    readonly retrieved?: any;
    readonly loading: boolean;
    readonly error?: string;
    readonly eventSource?: EventSource;
    readonly deleteError?: string;
    readonly deleteLoading: boolean;
    readonly authToken: string;
    readonly deleted?: any;
    readonly retrieve: typeof retrieve;
    // readonly reset: typeof reset;
    readonly reset: any;
    readonly del: typeof del;
    readonly clinicProfile: typeof IClinicProfileState;
    readonly getClinicProfile: typeof getClinicProfile;
}

interface IExternalClinicViewProps {
}

interface IClinicViewProps extends IConnectedClinicViewProps,
    IExternalClinicViewProps,
    RouteComponentProps {
}

interface IClinicViewState {
    deleteModalShown: boolean;
    isLoading: boolean;
    clinicId: string;
    clinicProfile: typeof IClinicProfileState;
    clinicName: string;
}

class ClinicView extends React.Component<IClinicViewProps, IClinicViewState> {
    constructor(props: IClinicViewProps) {
        super(props);

        this.state = {
            deleteModalShown: false,
            isLoading: false,
            clinicId: (this.props.match.params as any).id,
            clinicProfile: this.props.clinicProfile,
            clinicName: ''
        };
    }

    componentDidMount() {
        const slug = this.props.match.params;
        this.props.retrieve(`clinics/${(slug as any).id}`, this.props.authToken);
        this.getClinicProfile();
    }

    componentWillUnmount() {
        if (this.props.eventSource) {
            this.props.reset(this.props.eventSource);
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IClinicViewProps>,
        prevState: Readonly<IClinicViewState>,
        snapshot?: any
    ): void {
        if (this.props.clinicProfile.id !== prevProps.clinicProfile.id) {
            this.setState({
                clinicProfile: this.props.clinicProfile,
                clinicName: this.props.clinicProfile.companyName
            });
        }

        if (this.props.match.params !== prevProps.match.params) {
            this.props.retrieve(`clinics/${(this.props.match.params as any).id}`, this.props.authToken);
            this.getClinicProfile();
        }
    }

    render() {
        if (this.props.deleted) return <Redirect to=".."/>;

    const item = this.props.retrieved,
        clinicTitle = this.state.clinicName ? `Clinic: ${this.state.clinicName}` : 'Clinic',
        clinicLogin = item?.account?.user?.login;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">{clinicTitle}</div>
                            <div className="action-container">
                                <div onClick={() => this.props.history.goBack()} className="btn btn-no-outline">
                                    <span className="feather icon-chevron-left"/>
                                    <Translation text={"button.back"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <ClinicTreatments clinic={item}
                                          clinicId={this.state.clinicId}/>
                        {/*<ClinicCommission clinic={item}*/}
                        {/*                  clinicId={this.state.clinicId}/>*/}
                        <GeneralInformation clinicProfile={this.props.clinicProfile}
                                            authToken={this.props.authToken}/>
                        <ClinicPhotos clinic={item}
                                      clinicId={this.state.clinicId}/>
                        <ClinicDetails clinicProfile={this.props.clinicProfile}
                                       authToken={this.props.authToken}/>
                        <ClinicSpecialists clinicId={this.state.clinicId}
                                           authToken={this.props.authToken}/>
                    </div>

                    <div className="col-xl-6 col-md-12">
                        <ClinicSubscriptionPlan clinicLogin={clinicLogin}
                                                clinic={item}
                                                clinicId={this.state.clinicId}/>
                        <ClinicInvoices clinicId={this.state.clinicId}/>
                    </div>
                </div>

                {/*<CustomCard showLocalLoader={this.props.loading}>*/}
                {/*    <CustomCard.Body>*/}

                {/*        {item && (*/}
                {/*            <table className="table table-striped table-hover">*/}
                {/*                <thead>*/}
                {/*                <tr>*/}
                {/*                    <th>Field</th>*/}
                {/*                    <th>Value</th>*/}
                {/*                </tr>*/}
                {/*                </thead>*/}
                {/*                <tbody>*/}
                {/*                <tr>*/}
                {/*                    <th scope="row">logo</th>*/}
                {/*                    <td><img src={item.logo && item.logo.contentUrl} alt="clinic logo"/></td>*/}
                {/*                </tr>*/}
                {/*                <tr>*/}
                {/*                    <th scope="row">name</th>*/}
                {/*                    <td>{`${item.account.firstName} ${item.account.lastName}`}</td>*/}
                {/*                </tr>*/}
                {/*                <tr>*/}
                {/*                    <th scope="row">reference</th>*/}
                {/*                    <td>{item.reference}</td>*/}
                {/*                </tr>*/}
                {/*                <tr>*/}
                {/*                    <th scope="row">feePercent</th>*/}
                {/*                    <td>{item.feePercent}</td>*/}
                {/*                </tr>*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        )}*/}
                {/*    </CustomCard.Body>*/}
                {/*</CustomCard>*/}

                {/*{item && (*/}
                {/*  <Link to={`/dashboard/clinics`}>*/}
                {/*    <button className="btn btn-warning">Edit</button>*/}
                {/*  </Link>*/}
                {/*)}*/}
                {/*<button onClick={() => this.toggleDeleteModal()} className="btn btn-danger">*/}
                {/*  Delete*/}
                {/*</button>*/}

                <BasicModal isModalShown={this.state.deleteModalShown} closeModal={this.closeModal}>
                    <CustomCard type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text="modal.confirm"/>
                                <button className="btn-modal-close" onClick={() => this.toggleDeleteModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete clinic?
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-danger-outline mr-4"
                                        onClick={() => this.toggleDeleteModal()}>
                                    <span className="feather icon-minus-circle"/>
                                    <Translation text={'button.no'}/>
                                </button>
                                <button className="btn btn-theme"
                                        onClick={() => this.deleteClinic()}>
                                    <span className="feather icon-check-circle"/>
                                    <Translation text={'button.yes'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private toggleDeleteModal = () => {
        this.setState({deleteModalShown: !this.state.deleteModalShown});
    };
    private closeModal = () => {
        return this.setState({deleteModalShown: false});
    };
    private deleteClinic = () => {
        this.props.del(this.props.retrieved, this.props.authToken);
    };

    private getClinicProfile = (): any => {
        if (!(this.props.location as any).state.profileId) {
            return;
        }
        this.props.getClinicProfile((this.props.location as any).state.profileId);
    };
}

export default connect(
    (state: RootState) => ({
        retrieved: retrievedClinicItemSelector(state),
        error: clinicItemErrorSelector(state),
        loading: clinicItemLoadingSelector(state),
        eventSource: clinicItemEventSourceSelector(state),
        deleteError: clinicItemDeleteErrorSelector(state),
        deleteLoading: clinicItemDeleteLoadingSelector(state),
        deleted: clinicItemDeletedSelector(state),
        authToken: authTokenSelector(state),
        clinicProfile: selectClinicProfile(state),
    }),
    {
        retrieve,
        del,
        reset,
        addSpecialist,
        getClinicProfile
    }
)(ClinicView);
