import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiSelectType,
    ValidationRules
} from 'meditrip-common-web';

export const recommendationFrequencyUnits = [
    {
        value: 'day',
        label: 'day'
    },
    {
        value: 'week',
        label: 'week'
    },
    {
        value: 'month',
        label: 'month'
    }
];

export const reminderFrequencyUnits = [{value: 'hour', label: 'hour'}, ... recommendationFrequencyUnits];

export const addTravelPlannerFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'recommendationName',
            class: 'form-row',
            controls: {
                recommendationName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.eventName',
                    label: 'aftercare.predefinedEvents.addEventModal.form.eventName',
                    type: InputType.TEXT,
                    hostClass: 'col-100'
                }
            }
        },
        {
            controlType: 'group',
            key: 'recommendationDescription',
            class: 'form-row',
            controls: {
                recommendationDescription: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.TEXTAREA,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.eventDescription',
                    label: 'aftercare.predefinedEvents.addEventModal.form.eventDescription',
                    type: InputType.TEXT,
                    hostClass: 'col-100'
                }
            }
        },
        {
            controlType: 'group',
            key: 'recommendationReminder',
            class: 'form-row',
            controls: {
                reminderAmount: {
                    hostClass: 'col-33',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.selectAmountPlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'aftercare.predefinedEvents.addEventModal.form.reminderDate',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: () => getDefaultIntervalOptions(23, 0),
                    inputDataMapper: (data: any) => {
                        if (!data && data !== 0) {
                            return [];
                        }

                        return addTravelPlannerFormConfig.controls[2].controls.reminderAmount.multiselectOptions
                            .filter((reminder: any) => reminder.value === data)
                            .map((recommendationReminder: any) => ({
                                value: recommendationReminder.value,
                                label: recommendationReminder.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                },
                reminderUnit: {
                    hostClass: 'col-66 custom-input-group',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.selectTypePlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'aftercare.predefinedEvents.addEventModal.form.before',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: reminderFrequencyUnits,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return addTravelPlannerFormConfig.controls[2].controls.reminderUnit.multiselectOptions
                            .filter((reminder: any) => reminder.value === data)
                            .map((reminderUnit: any) => ({
                                value: reminderUnit.value,
                                label: reminderUnit.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'recommendation_submit',
            class: 'form-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'aftercare.predefinedEvents.addEventButton',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => {
                        // if (mappedOutputValue.repeatEvent === 'single' && isFormValid) {
                        //     return false;
                        // }
                        //
                        // if (mappedOutputValue.repeatEvent === 'consecutive') {
                        //     return !(mappedOutputValue.consecutiveType &&
                        //         mappedOutputValue.consecutiveValue &&
                        //         mappedOutputValue.intervalUnit &&
                        //         mappedOutputValue.intervalValue);
                        //
                        // }
                        return !isFormValid;
                    }
                },
            },
        },
    ]
};

function getDefaultIntervalOptions(cycleValue: number, cycleStartValue?: number) {
    let options: typeof IMultiselectOption[] = [];
    let startValue = cycleStartValue ? cycleStartValue : 1;
    for (let step = startValue; step <= cycleValue; step++) {
        options.push({
            value: step,
            label: step
        })
    }
    return options;
}
