import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'meditrip-common-web';

export const treatmentCategoriesFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'treatments_categories',
            class: 'row',
            controls: {
                treatmentCategories: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectOptions: [],
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return data.map((category: any) => ({
                            value: category.id,
                            label: category.name,
                        }));
                    },
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'No extra services selected',
                    label: 'Extra Services',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            },
        },
        {
            controlType: 'group',
            key: 'treatments_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2 pr-0',
                    defaultValue: null,
                    btnText: 'Save',
                    defaultStyles: 'btn btn-primary'
                },
            },
        },
    ]
};

