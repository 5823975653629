import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";

export function addTreatmentCategoryAPI(
    accessToken: string,
    name: string
): Observable<any> {
    return treatmentTypeAPI.post(
        `treatment_categories`,
        {
            name: name
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
